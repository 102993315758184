import Layout from "@components/Layout";
import { Body, Subtitle, Title } from "@components/Typography";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import ContactBlock from "@blocks/ContactBlock";

import founder1 from "@images/GreyBW-6480.jpg";
import founder2 from "@images/GreyBW-6544.jpg";

const Container = tw.div`bg-pink flex flex-col px-[25px] tablet:px-[50px] pb-[100px] tablet:pb-[150px]`;

const TextContainer = tw.div`flex flex-col flex-1 max-w-[200px] tablet:max-w-[300px] relative justify-center items-center pointer-events-none`;
const TextItem = tw.div`relative max-w-[1200px]  my-[25px] tablet:my-[50px] block text-[40px] text-white mx-auto`;

const MainContainer = tw.div`flex flex-col justify-center items-center`;
const CenteredAnimationContainer = tw.div`relative w-[100%] max-h-[100vh] h-[100vh] tablet:h-[auto] flex justify-center items-center flex-col`;
const AnimationContainer = tw.div`relative w-[100%] max-w-[100vh] before:pb-[100%] before:content-[""] before:bg-midnight flex justify-center items-center self-center mx-auto`;
const Animation = tw.div`h-[100%] w-[100%] absolute top-0 left-0`;
const NodeContainer = styled.a(({ hover }) => [
  tw`absolute h-[7%] w-[7%] mobile:h-[5%] mobile:w-[5%] tablet:h-[4%] tablet:w-[4%] border-2 border-green rounded-full justify-center items-center flex opacity-0 cursor-pointer`,
  hover ? tw`bg-green` : tw`bg-transparent`,
]);

const Atom = tw.div`absolute h-[35%] w-[35%] bg-green rounded-full`;




const TextContent = [
  "",
  "All of our tutors are full time, professional tutors",
  "Our tutors also bring real world experience, with many accomplished scientists, musicians and writers",
  "All of our tutors hold safeguarding levels 2 or 3",
  "All of our tutors are enhanced DBS verified",
  "All of our tutors have degrees from Russell Group universities",
  "75% of our tutors graduated from Oxbridge",
  "Half of our tutors have PGCE with QTS",
  "All of our tutors have at least 5 years full time experience",
  "100% of our tutors have at least 5000 hours accrued teaching their subject",
  "We have 59 prize winners from Oxford and Cambridge",
  "Our tutors collectively speak 24 different languages",
  "70% of our tutors hold at least one postgraduate degree",
];

const Node = ({ onMouseEnter, onMouseLeave, setRef }) => {
  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(true);
    onMouseEnter();
  };

  const handleLeave = () => {
    setHover(false);
    onMouseLeave();
  };

  return (
    <NodeContainer
      hover={hover}
      onMouseOver={handleHover}
      onMouseLeave={handleLeave}
      ref={setRef}
    >
      <Atom />
    </NodeContainer>
  );
};
const CirclePath = ({ setRef, pathRef }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 335 335"
      ref={setRef}
      pointerEvents="none"
      style={{
        opacity: 0,
        position: "absolute",
      }}
    >
      <path
        vectorEffect="non-scaling-stroke"
        id="path"
        d="M333.3,167.2c0,91.7-74.4,166.1-166.1,166.1S1.1,258.9,1.1,167.2S75.5,1.1,167.2,1.1S333.3,75.5,333.3,167.2"
        ref={pathRef}
        style={{
          fill: "none",
          stroke: "#084036",
          strokeMiterLimit: 10,
          strokeWidth: 2,
          opacity: 0.33,
        }}
      />
    </svg>
  );
};

// markup
const Contact = () => {
  const textRef = useRef(null);
  const mobileTextRef = useRef(null);
  const instructionsRef = useRef(null);
  const mobileInstructionsRef = useRef(null);

  const titleRef = useRef(null);
  const mobileTitleRef = useRef(null);

  const tl = useRef(null);

  const screenDimensions = useRef(null);

  const appearTimeline = useRef(null);
  const formationTimeline = useRef(null);
  const nodeTimeline1 = useRef(null);
  const nodeTimeline2 = useRef(null);
  const nodeTimeline3 = useRef(null);

  const [currentText, setCurrentText] = useState("");
  const [currentTextIndex, setCurrentTextIndex] = useState(null);
  const [isMount, setMount] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);

  const node1path = useRef(null);
  const node1 = useRef(null);
  const node2 = useRef(null);
  const node3 = useRef(null);
  const node4 = useRef(null);
  const node5 = useRef(null);
  const node6 = useRef(null);

  const node2path = useRef(null);
  const node7 = useRef(null);
  const node8 = useRef(null);
  const node9 = useRef(null);

  const node3path = useRef(null);
  const node10 = useRef(null);
  const node11 = useRef(null);
  const node12 = useRef(null);

  gsap.registerPlugin(MotionPathPlugin);

  gsap.registerEffect({
    name: "swapText",
    effect: (targets, config) => {
      if (disabled) return;

      tl.current && tl.current.kill();

      tl.current = gsap.timeline();

      if (config.fadeTitle) {
        tl.current.to(
          [
            titleRef.current,
            instructionsRef.current,
            mobileInstructionsRef.current,
          ],
          { opacity: 0 },
          0
        );
      } else {
        tl.current.to(targets, { opacity: 0 }, 0);
      }

      tl.current.add(() => setCurrentText(config.text));
      tl.current.to(targets, { opacity: 1 });
      return tl;
    },
    defaults: { duration: 0.5 },
    extendTimeline: true,
  });

  gsap.registerEffect({
    name: "showIntroText",
    effect: (targets, config) => {
      if (disabled) return;

      tl.current && tl.current.kill();

      tl.current = gsap.timeline();

      tl.current.to(targets, {
        opacity: 0,
        delay: 5,
        onComplete: () => setCurrentText(""),
      });

      tl.current.to(
        [
          titleRef.current,
          mobileTitleRef.current,
          instructionsRef.current,
          mobileInstructionsRef.current,
        ],
        {
          opacity: 1,
        }
      );
      return tl;
    },
    defaults: { duration: 0.5 },
    extendTimeline: true,
  });

  useEffect(() => {
    if (disabled) return;

    currentTextIndex
      ? gsap.effects.swapText([mobileTextRef.current, textRef.current], {
        text: TextContent[currentTextIndex],
        fadeTitle: currentText === "",
      })
      : gsap.effects.showIntroText([mobileTextRef.current, textRef.current], {
        text: TextContent[currentTextIndex],
        fadeTitle: currentText === "",
      });
  }, [currentTextIndex, setCurrentText]);

  const nodeAppearAnimation = () => {
    appearTimeline.current = gsap.timeline().timeScale(0.5);
    formationTimeline.current = gsap.timeline({ paused: true });
    nodeTimeline1.current = gsap.timeline({ paused: true });
    nodeTimeline2.current = gsap.timeline({ paused: true });
    nodeTimeline3.current = gsap.timeline({ paused: true });

    appearTimeline.current
      .fromTo(
        circle1.current,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 1,
          scaleY: 1,
          opacity: 1,
          duration: 0.5,
        },
        0
      )
      .fromTo(
        circle2.current,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 0.75,
          scaleY: 0.75,
          opacity: 1,
          duration: 0.5,
        },
        0.1
      )
      .fromTo(
        circle3.current,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 0.6,
          scaleY: 0.6,
          opacity: 1,
          duration: 0.5,
        },
        0
      )
      .fromTo(
        circle4.current,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 0.5,
          scaleY: 0.5,
          opacity: 1,
          duration: 0.5,
        },
        0
      )
      .fromTo(
        circle5.current,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 0.3,
          scaleY: 0.3,
          opacity: 1,
          duration: 0.5,
        },
        0
      );

    formationTimeline.current
      .to(
        circle1.current,

        {
          scaleX: 1,
          scaleY: 0.3,
          opacity: 1,
          duration: 0.5,
        },
        0
      )
      .to(
        circle2.current,

        {
          scaleX: 1,
          scaleY: 0.3,
          rotate: 45,
          duration: 0.5,
        },
        0.1
      )
      .to(
        circle3.current,

        {
          scaleX: 1,
          scaleY: 0.3,
          rotate: -45,
          duration: 0.5,
        },
        0.2
      )
      .to(
        circle4.current,

        {
          scaleX: 0.65,
          scaleY: 0.65,
          opacity: 1,
          duration: 0.5,
        },
        0.3
      )
      .to(
        circle5.current,

        {
          scaleX: 0.41,
          scaleY: 0.65,
          opacity: 1,
          duration: 0.5,
        },
        0.3
      );

    nodeTimeline1.current
      .to(
        node6.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.97,
          },
        },
        0
      )
      .to(
        node1.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.89,
          },
        },
        0
      )
      .to(
        node2.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.61,
          },
        },
        0.2
      )
      .to(
        node3.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.47,
          },
        },
        0.3
      )
      .to(
        node4.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.35,
          },
        },
        0.4
      )
      .to(
        node5.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node1path.current,
            align: node1path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.15,
          },
        },
        0.5
      );

    nodeTimeline2.current
      .to(
        node9.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node2path.current,
            align: node2path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.55,
          },
        },
        0.5
      )
      .to(
        node7.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node2path.current,
            align: node2path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 1.05,
          },
        },
        0
      )
      .to(
        node8.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node2path.current,
            align: node2path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            end: 0.925,
          },
        },
        0.2
      );

    nodeTimeline3.current
      .to(
        node10.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node3path.current,
            align: node3path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: 1,
            end: 0.58,
          },
        },
        0.1
      )
      .to(
        node11.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node3path.current,
            align: node3path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: 1,
            end: 0.43,
          },
        },
        0.2
      )
      .to(
        node12.current,
        {
          opacity: 1,
          duration: 3,
          ease: "power1.inOut",
          motionPath: {
            path: node3path.current,
            align: node3path.current,
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: 0,
            end: 0.95,
          },
          onComplete: () => {
            tl.current = gsap.timeline();
            tl.current.to([mobileTitleRef.current, titleRef.current], {
              opacity: 1,
              duration: 2,
            });
            setDisabled(false);
          },
        },
        0
      );

    appearTimeline.current.eventCallback("onComplete", function () {
      formationTimeline.current.play(0).timeScale(0.5);
    });

    formationTimeline.current.eventCallback("onComplete", function () {
      nodeTimeline1.current.play(0);
      nodeTimeline2.current.play(0);
      nodeTimeline3.current.play(0);
    });
  };

  useEffect(() => {
    if (isMount) {
      screenDimensions.current = window.innerWidth;
      nodeAppearAnimation();

      gsap.registerPlugin(ScrollTrigger);

      const animateFrom = (elem, direction) => {
        direction = direction || 1;
        var x = 0,
          y = direction * 200;
        elem.style.transform = "translate(" + x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(
          elem,
          { x: x, y: y, autoAlpha: 0 },
          {
            duration: 3.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto",
          }
        );
      };

      gsap.utils.toArray(`.reveal-text`).forEach(function (elem) {
        gsap.set(elem, { autoAlpha: 0 });

        ScrollTrigger.create({
          trigger: elem,
          once: true,
          invalidateOnRefresh: true,
          onEnter: function () {
            animateFrom(elem);
          },
        });
      });
    }
    setMount(true);
  }, [isMount]);

  useEffect(() => {
    function handleResize() {
      if (screenDimensions.current !== window.innerWidth) {
        screenDimensions.current = window.innerWidth;
        if (appearTimeline.current) {
          appearTimeline.current.restart().kill();
          formationTimeline.current.restart().kill();
          nodeTimeline1.current.restart().kill();
          nodeTimeline2.current.restart().kill();
          nodeTimeline3.current.restart().kill();
          nodeAppearAnimation();
        } else {
          nodeAppearAnimation();
        }
        // Do something
      }
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleHover = (index) => {
    setCurrentTextIndex(index);
  };

  const handleLeave = () => {
    setCurrentTextIndex(null);
  };

  if (!isMount) {
    return <div></div>;
  }

  return (
    <Layout>
      <Container>
        <CenteredAnimationContainer>
          <AnimationContainer>
            <Animation>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(1)}
                setRef={node1}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(2)}
                setRef={node2}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(3)}
                setRef={node3}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(4)}
                setRef={node4}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(5)}
                setRef={node5}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(6)}
                setRef={node6}
              ></Node>

              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(7)}
                setRef={node7}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(8)}
                setRef={node8}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(9)}
                setRef={node9}
              ></Node>

              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(10)}
                setRef={node10}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(11)}
                setRef={node11}
              ></Node>
              <Node
                onMouseLeave={handleLeave}
                onMouseEnter={() => handleHover(12)}
                setRef={node12}
              ></Node>

              <CirclePath setRef={circle1} pathRef={node1path} />
              <CirclePath setRef={circle2} pathRef={node2path} />
              <CirclePath setRef={circle3} pathRef={node3path} />
              <CirclePath setRef={circle4} />
              <CirclePath setRef={circle5} />
            </Animation>
            <TextContainer>
              <div
                ref={mobileTitleRef}
                style={{
                  position: "absolute",
                  opacity: 0,
                }}
              >
                <div className="flex flex-col gap-[5px] tablet:gap-[10px] top-[-5px] relative tablet:top-[-10px] tablet:hidden">
                  <Subtitle color="gold">{"01"}</Subtitle>
                  <Title small color="midnight" className="text-center">
                    {"Our Network"}
                  </Title>
                </div>
              </div>

              <div
                ref={titleRef}
                style={{
                  position: "absolute",
                  opacity: 0,
                }}
              >
                <div className="flex-col gap-[5px] tablet:gap-[10px] top-[-5px] relative tablet:top-[-10px] hidden tablet:flex">
                  <Subtitle color="gold">{"01"}</Subtitle>
                  <Title small color="midnight" className="text-center">
                    {"Our Network"}
                  </Title>
                </div>
                <div
                  ref={instructionsRef}
                  style={{
                    opacity: 1,
                    top: 50,
                    margin: "0 auto",
                    position: "relative",
                    maxWidth: 200,
                  }}
                >
                  <Subtitle
                    style={{ fontStyle: "italic" }}
                    color="gold"
                    small
                    className="hidden tablet:flex"
                  >
                    Select a node to find out more
                  </Subtitle>
                </div>
              </div>
              <div
                ref={textRef}
                style={{
                  opacity: 0,
                }}
              >
                <Subtitle
                  className="hidden tablet:flex"
                  style={{ fontStyle: "italic" }}
                  color="midnight"
                  small
                >
                  {ReactHtmlParser(currentText)}
                </Subtitle>
              </div>
            </TextContainer>
          </AnimationContainer>

          <div
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              ref={mobileTextRef}
              style={{
                left: 0,
                right: 0,
                margin: "auto auto",
                position: "absolute",
                opacity: 0,
              }}
            >
              <Subtitle
                className="flex tablet:hidden justify-center text-center"
                style={{ fontStyle: "italic" }}
                color="midnight"
                small
              >
                {ReactHtmlParser(currentText)}
              </Subtitle>
            </div>
            <div ref={mobileInstructionsRef} style={{ position: "absolute" }}>
              <Subtitle
                className="flex tablet:hidden"
                style={{ fontStyle: "italic" }}
                color="gold"
                small
              >
                Select a node to find out more
              </Subtitle>
            </div>
          </div>
        </CenteredAnimationContainer>

        <MainContainer>
          <TextItem className={`text text-center reveal-text !tablet:mt-[0px]`}>
            <div className="flex flex-col gap-[5px] tablet:gap-[10px]">
              <Subtitle color="gold">{"02"}</Subtitle>
              <Title small color="midnight" className="text-center">
                {"Our Legacy"}
              </Title>
            </div>
          </TextItem>
          <Body className="text-center reveal-text">
            Our promise to families has remained the same since we were founded
            in 2015. To impart in their children a mindset of continuous
            improvement, aspiration to contribute and unceasing drive for
            success. Founded by educators, Think Tutors have a tradition of
            providing elite tuition, mentoring and educational advisory. Our
            motto, non scholae sed vitae discimus, translates to, “we do not
            learn for school, but for life”, a belief which drives our actions.
            Think Tutors operate at the vanguard of educational innovation,
            utilising advanced learning techniques to drive results, and bespoke
            systems to monitor progress.
          </Body>

          <TextItem className={`text text-center reveal-text`}>
            <div className="flex flex-col gap-[5px] tablet:gap-[10px]">
              <Subtitle color="gold">{"03"}</Subtitle>
              <Title small color="midnight" className="text-center">
                {"Our Team"}
              </Title>
            </div>
          </TextItem>
          <Body className="text-center reveal-text">
            We are led by a multi-award-winning senior management team, who are
            focused on cultivating minds to build confidence and think without
            limits. Preserving educational relationships spanning from Eton to
            MIT, our senior management team have a reputation for streamlining
            admissions processes by showcasing excellence. Globally connected
            with an authentically British attitude to education. Think Tutors
            are based in Berkeley Square, Mayfair and with operations spanning
            the rest of the globe, we can provide tuition and mentoring at short
            notice anywhere in the world.
          </Body>
          </MainContainer>

        <MainContainer>
          <TextItem className={`text text-center reveal-text`}>
            <div className="flex flex-col gap-[5px] tablet:gap-[10px]">
              <Subtitle color="gold">{"04"}</Subtitle>
              <Title small color="midnight" className="text-center">
                {"Our Founders"}
              </Title>
            </div>
          </TextItem>
          <Body className="text-center reveal-text">
            Founded in 2015 by James Mitchell and Neil Ridley, our team is dedicated to making a significant impact within the educational sphere. We are fervent about sparking innovation in education and equipping future generations with the tools they need to thrive.
          </Body>
          <div className="flex flex-col tablet:flex-row gap-[20px] tablet:gap-[40px] mt-[40px] reveal-text relative">
            <FounderImageContainer>
              <FounderImage src={founder1} />
            </FounderImageContainer>
            <FounderImageContainer>
              <FounderImage src={founder2} />
            </FounderImageContainer>
          </div>
        </MainContainer>

        {/* <div tw="w-full flex flex-col items-center justify-center bg-midnight h-[100px]"></div> */}

      </Container>
      
      <ContactBlock contactPage={false} />
    </Layout>
  );
};



const FounderImageContainer = tw.div`w-[200px] h-[200px] overflow-hidden`;
const FounderImage = tw.img`w-full h-full object-cover object-center`;


export default Contact;
